import React, { useEffect, useRef } from 'react'
import classnames from 'classnames'
import useIntersectionObserver from '~components/global/IntersectionObserver'
import useMediaQuery from '~hooks/useMediaQuery'

import './index.scss'

const TwoImagesCaption = ({ data }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-30%",
    threshold: 0
  })
  const isMobile = useMediaQuery('(max-width: 768px)')

  const leftVideoRef = useRef(null);
  const rightVideoRef = useRef(null);

  useEffect(() => {
    if (leftVideoRef.current) {
      leftVideoRef.current.muted = true
    }
    if (rightVideoRef.current) {
      rightVideoRef.current.muted = true
    }
  }, [])

  const captions = () => {
    const captionsClass = classnames(
      'two-images-caption__captions--wrapper',
      `two-images-caption__captions--wrapper--${data.image_caption_position}`,
      { 'two-images-caption__captions--wrapper--end': (!data.left_image_caption && data.right_image_caption) || (data.left_image_caption && data.right_image_caption) },
      { 'two-images-caption__captions--wrapper--start' : data.left_image_caption && !data.right_image_caption }
    )
    return (
      <div className="two-images-caption__captions">
        <div className={captionsClass}>
          { data.left_image_caption &&
            caption(data.left_image_caption, 'left')
          }
          { data.right_image_caption &&
            caption(data.right_image_caption, 'right')
          }
        </div>
      </div>
    )
  }

  const caption = (captionText, side) => {
    const captionClass = classnames(
      'two-images-caption__caption--wrapper',
      `two-images-caption__caption--wrapper--${side}`,
      `two-images-caption__caption--wrapper--${data.image_caption_position}`
    )

    return (
      <div className={captionClass}>
        <p className="two-images-caption__caption">{captionText}</p>
      </div>
    )
  }

  if (isMobile) {
    return (
      <section className="two-images-caption" ref={componentRef}>
        <div className="two-images-caption__container">
          <div className="two-images-caption__images">
            <div className="two-images-caption__images__left">
              { data.left_image_caption && data.image_caption_position === "top" &&
                caption(data.left_image_caption, 'left')
              }
              <div className="two-images-caption__image--wrapper two-images-caption__image--wrapper--left">
                {data.left_video ?
                  <video ref={leftVideoRef} alt="" class="two-images-caption__image"
                    src={data.left_video} poster={data.left_image}
                    playsInline muted autoPlay loop></video> :
                  <img className="two-images-caption__image" src={data.left_image} alt={data.left_image_alt} />
                }
              </div>
              { data.left_image_caption && data.image_caption_position === "bottom" &&
                caption(data.left_image_caption, 'left')
              }
            </div>
            <div className="two-images-caption__images__right">
              { data.right_image_caption && data.image_caption_position === "top" &&
                caption(data.right_image_caption, 'right')
              }
              <div className="two-images-caption__image--wrapper two-images-caption__image--wrapper--right">
                {data.right_video ?
                  <video ref={rightVideoRef} alt="" class="two-images-caption__image"
                    src={data.right_video} poster={data.right_image}
                    playsInline muted autoPlay loop></video> :
                  <img className="two-images-caption__image" src={data.right_image} alt={data.right_image_alt} />
                }
              </div>
              { data.right_image_caption && data.image_caption_position === "bottom" &&
                caption(data.right_image_caption, 'right')
              }
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className="two-images-caption" ref={componentRef}>
        <div className="two-images-caption__container">
          { (data.left_image_caption || data.right_image_caption) && data.image_caption_position === "top" &&
            captions()
          }

          <div className="two-images-caption__images">
            <div className="two-images-caption__image--wrapper two-images-caption__image--wrapper--left">
              { isMobile && data.left_image_caption && data.image_caption_position === "top" &&
                caption(data.left_image_caption)
              }
              {data.left_video ?
                <video ref={leftVideoRef} alt="" class="two-images-caption__image"
                  src={data.left_video} poster={data.left_image}
                  playsInline muted autoPlay loop></video> :
                <img className="two-images-caption__image" src={data.left_image} alt={data.left_image_alt} />
              }
              { isMobile && data.left_image_caption && data.image_caption_position === "bottom" &&
                caption(data.left_image_caption)
              }
            </div>
            <div className="two-images-caption__image--wrapper two-images-caption__image--wrapper--right">
              { isMobile && data.right_image_caption && data.image_caption_position === "top" &&
                caption(data.right_image_caption)
              }
              {data.right_video ?
                <video ref={rightVideoRef} alt="" class="two-images-caption__image"
                  src={data.right_video} poster={data.right_image}
                  playsInline muted autoPlay loop></video> :
                <img className="two-images-caption__image" src={data.right_image} alt={data.right_image_alt} />
              }
              { isMobile && data.right_image_caption && data.image_caption_position === "bottom" &&
                caption(data.right_image_caption)
              }
            </div>
          </div>

          { (data.left_image_caption || data.right_image_caption) && data.image_caption_position === "bottom" &&
            captions()
          }
        </div>
      </section>
    )
  }
}

export default TwoImagesCaption
