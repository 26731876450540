import React, { useEffect, useRef } from 'react'
import useIntersectionObserver from '~components/global/IntersectionObserver'
import './index.scss'

const ImageCard = ({ data }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-45%",
    threshold: 0
  })

  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true
    }
  }, [])

  return (
    <section className="image-card" ref={componentRef}>
      <div className="image-card__container">
        <div className="image-card__content">
          <div className="image-card__body--wrapper">
            <div className="image-card__body">
              <p className="image-card__body__text">{data.text}</p>
            </div>
          </div>
          <div className="image-card__image--wrapper">
            {data.video ?
              <video ref={videoRef} alt="" class="image-card__image"
                src={data.video} poster={data.image}
                playsInline muted autoPlay loop></video> :
              <img className="image-card__image" src={data.image} alt={data.alt} />
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImageCard
