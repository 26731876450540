import React from 'react'
import DoubleRuler from '~components/DoubleRuler'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const CaseStudyOverview = ({ data, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  const rulerData = {
    firstLabel: {
      text: data.project_number,
      color: 'brand',
      location: 'outer-left'
    },
    secondLabel: {
      text: data.project_name,
      color: 'grey',
      location: 'inner-left'
    },
    thirdLabel: {
      text: data.project_type,
      color: 'brand',
      location: 'inner-right'
    },
    fourthLabel: {
      text: data.client_name,
      color: 'grey',
      location: 'outer-right'
    }
  }

  return (
    <section className="case-study-overview" ref={componentRef}>
      <div className="case-study-overview__container">
        <DoubleRuler data={rulerData} isPreview={isPreview} />
        <div className="case-study-overview__content">
          <p className="case-study-overview__text">{data.text}</p>
          {(data.recognition && data.recognition.length > 0) &&
            <div className="case-study-overview__recognitions">
              <p className="case-study-overview__recognitions__label">Recognition</p>
              <ul className="case-study-overview__recognitions__list">
                {data.recognition.map((recognition, index) =>
                  <li className="case-study-overview__recognition" key={`recognition-${index}`}>
                    <p className="case-study-overview__recognition__name">{recognition.recognition_name}</p>
                    <p className="case-study-overview__recognition__category">[{recognition.recognition_category},<span className="case-study-overview__recognition__year">{recognition.recognition_year}</span>]</p>
                  </li>
                )}
              </ul>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default CaseStudyOverview
