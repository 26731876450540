import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import useMediaQuery from '~hooks/useMediaQuery'

import Button from '~components/Button'

import './index.scss'

const NextUp = ({ data, eventId, isPreview }) => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const sectionEl = useRef(null);
  const imageEl = useRef(null);
  const [isIn, setIsIn] = useState(false);

  const triggerExit = () => {
    if (!sectionEl.current || !imageEl.current) {
      return;
    }

    window.dispatchEvent(new CustomEvent('case-study-next-up-unload', {
      detail: { eventId },
    }))

    imageEl.current.style.transform = 'none';
    const rect = imageEl.current.getBoundingClientRect();
    imageEl.current.style.transform = null;
    imageEl.current.style.top = `${rect.top}px`;
    imageEl.current.style.left = `${rect.left}px`;
    imageEl.current.style.width = `${rect.width}px`;
    imageEl.current.style.height = `${rect.height}px`;
    sectionEl.current.classList.add('pre-exit')

    setTimeout(() => {
      sectionEl.current.classList.add('is-exiting');
      imageEl.current.style.top = null;
      imageEl.current.style.left = null;
      imageEl.current.style.width = null;
      imageEl.current.style.height = null;
    })
  }

  const buttonData = {
    tag: 'transitionlink',
    text: 'View case study',
    url: `/work/${data.work_id}`,
  }

  if (!isMobile) {
    buttonData.exit = {
      length: 1.2,
      trigger: triggerExit,
    }

    buttonData.entry = {
      length: 1.3,
      appearAfter: 1.2,
      state: { type: 'next-up' }
    }
  }

  useEffect(() => {
    if (document.body && sectionEl.current) {
      const scrollHandler = () => {
        const bound = document.body.clientHeight - (window.innerHeight * 0.5) - sectionEl.current.clientHeight;
        if (window.scrollY > bound && !isIn) {
          setIsIn(true);
          window.dispatchEvent(new CustomEvent('case-study-next-up-in-view', {
            detail: {
              inView: true,
              eventId,
            }
          }))
        } else if (window.scrollY <= bound && isIn) {
          setIsIn(false);
          window.dispatchEvent(new CustomEvent('case-study-next-up-in-view', {
            detail: {
              inView: false,
              eventId,
            }
          }))
        }
      };

      window.addEventListener('scroll', scrollHandler);

      return () => {
        window.removeEventListener('scroll', scrollHandler);
      };
    }
  }, [isIn, setIsIn, eventId])

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('case-study-next-up-load', {
      detail: { eventId },
    }))

    return () => {
      window.dispatchEvent(new CustomEvent('case-study-next-up-unload', {
        detail: { eventId },
      }))
    };
  }, [eventId])

  return (
    <section className="next-up" ref={sectionEl}>
      <div className="next-up__container">
        <p className="next-up__eyebrow">Next</p>
        <h2 className="next-up__title">{data.work_name}</h2>
        <Button data={buttonData} isPreview={isPreview} />
      </div>
      <div className="next-up__images">
        <div className="next-up__image-block">
          <img className="next-up__image" src={data.image_landscape} alt={data.work_name} />
        </div>
        <div className="next-up__image-block" ref={imageEl}>
          <img className="next-up__image" src={data.image_hero} alt={data.work_name} />
        </div>
      </div>
    </section>
  )
}

NextUp.propTypes = {
  data: PropTypes.shape({
    work_id: PropTypes.string,
    work_name: PropTypes.string,
    image_landscape: PropTypes.string,
    image_hero: PropTypes.string,
  }),
  eventId: PropTypes.string,
}

export default NextUp
