import React, { useEffect, useRef } from 'react'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const RightAlignedImage = ({ data }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-30%",
    threshold: 0
  })

  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true
    }
  }, [])

  return (
    <section className="right-aligned-image" ref={componentRef}>
      <div className="right-aligned-image__container">
        <div className="right-aligned-image__image--wrapper">
          {data.video ?
            <video ref={videoRef} alt="" class="right-aligned-image__image"
              src={data.video} poster={data.image}
              playsInline muted autoPlay loop></video> :
            <img className="right-aligned-image__image" src={data.image} alt={data.alt} />
          }
        </div>
      </div>
    </section>
  )
}

export default RightAlignedImage
