import React from 'react'

import Header from '~components/Header'

import WorkDetailHero from '~components/workDetail/Hero'
import NextUp from '~components/workDetail/NextUp'

// Repeatable Sections
import BigText from '~components/workDetail/BigText'
import FullSizeImage from '~components/workDetail/FullSizeImage'
import FullSizeImageText from '~components/workDetail/FullSizeImageText'
import CaseStudyOverview from '~components/workDetail/Overview'
import IndustriesDeliverables from '~components/workDetail/IndustriesDeliverables'
import ImageCard from '~components/workDetail/ImageCard'
import ImageCardCaption from '~components/workDetail/ImageCardCaption'
import RightAlignedImage from '~components/workDetail/RightAlignedImage'
import TwoImagesCaption from '~components/workDetail/TwoImagesCaption'
import ThreeImages from '~components/workDetail/ThreeImages'
import TwoImages from '~components/workDetail/TwoImages'

const WorkDetailPageWrap = ({ data, isPreview }) => {
  return (
    <>
      <Header isPreview={isPreview} />
      <WorkDetailHero title={data.work_name} subtitle={data.subtitle}
        image={data.image_hero} imageAlt={data.image_hero_alt} position={data.text_position} alignment={data.text_side}
        color={data.text_color} isPreview={isPreview} />
      {data.sections && data.sections.map((section, index) => {
        switch(section.type) {
          case 'big-text':
            return <BigText data={section} key={`work-section-${index}`} isPreview={isPreview} />
          case 'case_study_overview':
            return <CaseStudyOverview data={section} key={`work-section-${index}`} isPreview={isPreview} />
          case 'full_size_image':
            return <FullSizeImage data={section} key={`work-section-${index}`} isPreview={isPreview} />
          case 'industries_deliverables':
            return <IndustriesDeliverables data={section} key={`work-section-${index}`} isPreview={isPreview} />
          case 'image_card':
            return <ImageCard data={section} key={`work-section-${index}`} isPreview={isPreview} />
          case 'image_card_caption':
            return <ImageCardCaption data={section} key={`work-section-${index}`} isPreview={isPreview} />
          case 'right_aligned_image':
            return <RightAlignedImage data={section} key={`work-section-${index}`} isPreview={isPreview} />
          case 'two_images_caption':
            return <TwoImagesCaption data={section} key={`work-section-${index}`} isPreview={isPreview} />
          case 'three_images':
            return <ThreeImages data={section} key={`work-section-${index}`} isPreview={isPreview} />
          case 'two_images':
            return <TwoImages data={section} key={`work-section-${index}`} isPreview={isPreview} />
          case 'full_size_image_text':
            return <FullSizeImageText data={section} key={`work-section-${index}`} isPreview={isPreview} />
          default:
            return null
        }
      })}
      {data.nextCase && <NextUp data={data.nextCase} eventId={data.work_id} isPreview={isPreview} />}
    </>
  )
}

export default WorkDetailPageWrap
