import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const FullSizeImage = ({ data }) => {
  const videoRef = useRef(null);
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-35%",
    threshold: 0
  })

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true
    }
  }, [])

  return (
    <section className="work-full-size-image" ref={componentRef}>
      <div className="work-full-size-image__container">
        {data.video ?
          <video ref={videoRef} alt="" class="work-full-size-image__image"
            src={data.video} poster={data.image}
            playsInline muted autoPlay loop></video> :
          <img className="work-full-size-image__image" src={data.image} alt={data.alt ? data.alt : ''} />
        }
      </div>
    </section>
  )
}

FullSizeImage.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    alt: PropTypes.string,
  }),
}

export default FullSizeImage
