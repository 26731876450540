import React from 'react'
import Layout from '~global/Layout'

import WorkDetailPageWrap from '~pageWraps/WorkDetailPageWrap'

const Work = ({ pageContext }) => {
  return (
    <Layout>
      <WorkDetailPageWrap data={pageContext}/>
    </Layout>
  )
}

export default Work
