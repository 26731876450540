import React from 'react'
import PropTypes from 'prop-types'
import { TransitionState } from 'gatsby-plugin-transition-link'
import classnames from 'classnames'

import './index.scss'

const WorkDetailHero = ({ title, subtitle, image, imageAlt, position, color, alignment }) => {
  return (
    <section className="work-detail-hero">
      <TransitionState>
        {(data) => {
          // this fallback is needed for CMS preview
          const transitionStatus = data && data.entry.state.type === 'next-up' ? data.transitionStatus : 'entered';
          const containerClass = classnames(
            'work-detail-hero__container',
            {
              'is-hidden': transitionStatus === 'entering',
              'work-detail-hero__container--position-top': position === 'top',
              'work-detail-hero__container--position-bottom': position === 'bottom',
              'work-detail-hero__container--position-center': position === 'center',
              'work-detail-hero__container--alignment-right': alignment === 'right',
              'work-detail-hero__container--color-white': color === 'white'
            },
          )
          return (
            <div className={containerClass}>
              <h1 className="work-detail-hero__title">{title}</h1>
              <p className="work-detail-hero__subtitle">{subtitle}</p>
            </div>
          )
        }}
      </TransitionState>
      <img className="work-detail-hero__image" src={image} alt={imageAlt} />
    </section>
  )
}

WorkDetailHero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  color: PropTypes.string,
  position: PropTypes.string,
  alignment: PropTypes.string
}

export default WorkDetailHero
