import React, { useEffect, useRef } from 'react'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const IndustriesDeliverablesBlock = ({ industries, deliverables }) => {
  const industriesEls = industries.map((industry, index) =>
    <li className="industries-deliverables__industry" key={`industry-${index}`}>{industry.name}</li>
  )

  const deliverablesEls = deliverables.map((deliverable, index) =>
    <li className="industries-deliverables__deliverable" key={`deliverable-${index}`}>{deliverable.name}</li>
  )

  return (
    <div className="industries-deliverables__content__info">
      <div className="industries-deliverables__content__info__industries">
        <p className="industries-deliverables__content__info__label">Industries</p>
        <ul className="industries-deliverables__industry">
          {industriesEls}
        </ul>
      </div>
      <div className="industries-deliverables__content__info__deliverables">
        <p className="industries-deliverables__content__info__label">Deliverables</p>
        <ul className="industries-deliverables__deliverables">
          {deliverablesEls}
        </ul>
      </div>
    </div>
  )
}

const IndustriesDeliverables = ({ data }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true
    }
  }, [])

  return (
    <section className="industries-deliverables" ref={componentRef}>
      <div className="industries-deliverables__container">
        <div className="industries-deliverables__content">
          <IndustriesDeliverablesBlock industries={data.industry} deliverables={data.deliverable} />
          {data.video ?
            <video ref={videoRef} alt="" class="industries-deliverables__content__image"
              src={data.video} poster={data.image}
              playsInline muted autoPlay loop></video> :
            <img className="industries-deliverables__content__image" src={data.image} alt={data.alt} />
          }
        </div>
      </div>
    </section>
  )
}

export default IndustriesDeliverables
