import React, { useEffect, useRef } from 'react'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const ThreeImages = ({ data }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-35%",
    threshold: 0
  })

  const leftVideoRef = useRef(null);
  const middleVideoRef = useRef(null);
  const rightVideoRef = useRef(null);
  useEffect(() => {
    if (leftVideoRef.current) {
      leftVideoRef.current.muted = true
    }
    if (middleVideoRef.current) {
      middleVideoRef.current.muted = true
    }
    if (rightVideoRef.current) {
      rightVideoRef.current.muted = true
    }
  }, [])

  return (
    <section className="three-images" ref={componentRef}>
      <div className="three-images__container">
        <div className="three-images__images--wrapper">
          <div className="three-images__images">
            <div className="three-images__image--wrapper">
              {data.left_video ?
                <video ref={leftVideoRef} alt="" class="three-images__image"
                  src={data.left_video} poster={data.left_image}
                  playsInline muted autoPlay loop></video> :
                <img className="three-images__image" src={data.left_image} alt={data.left_image_alt} />
              }
            </div>
            <div className="three-images__image--wrapper">
              {data.middle_video ?
                <video ref={middleVideoRef} alt="" class="three-images__image"
                  src={data.middle_video} poster={data.middle_image}
                  playsInline muted autoPlay loop></video> :
                <img className="three-images__image" src={data.middle_image} alt={data.middle_image_alt} />
              }
            </div>
            <div className="three-images__image--wrapper">
              {data.right_video ?
                <video ref={rightVideoRef} alt="" class="three-images__image"
                  src={data.right_video} poster={data.right_image}
                  playsInline muted autoPlay loop></video> :
                <img className="three-images__image" src={data.right_image} alt={data.right_image_alt} />
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ThreeImages
