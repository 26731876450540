import React from 'react'
import PropTypes from 'prop-types'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const BigText = ({ data }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  return (
    <section className="work-big-text" ref={componentRef}>
      <div className="work-big-text__container">
        <p className="work-big-text__text">{data.text}</p>
      </div>
    </section>
  )
}

BigText.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
  }),
}

export default BigText
