import React, { useEffect, useRef } from 'react'
import classnames from 'classnames'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const FullSizeImageText = ({ data }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-30%",
    threshold: 0
  })

  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true
    }
  }, [])

  const bodyClass = classnames(
    'full-size-image-text__body',
    {
      'full-size-image-text__body--bottom': data.text_position === 'bottom'
    }
  )

  return (
    <section className="full-size-image-text" ref={componentRef}>
      <div className="full-size-image-text__container">
        <div className={bodyClass}>
          <p className="full-size-image-text__body__title">{data.title}</p>
          <p className="full-size-image-text__body__text">{data.text}</p>
        </div>
        <div className="full-size-image-text__image--wrapper">
          {data.video ?
            <video ref={videoRef} alt="" class="full-size-image-text__image"
              src={data.video} poster={data.image}
              playsInline muted autoPlay loop></video> :
            <img className="full-size-image-text__image" src={data.image} alt={data.image_alt} />
          }
        </div>
      </div>
    </section>
  )
}

export default FullSizeImageText
